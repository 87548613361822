import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import Thumb from '../../assets/svgs/thumbAbout.svg';
import About from '../../assets/svgs/about.svg';

const AboutPage = ({ location }) => {
  const { images: { edges = [] } = {} } = useStaticQuery(graphql`
    {
      images: allImageSharp(
        filter: { fluid: { originalName: { regex: "/(pc|flux).png/" } } }
      ) {
        edges {
          node {
            fluid {
              originalName
            }
          }
        }
      }
    }
  `);

  const Pc = find(edges, ({ node }) => node.fluid.originalName === 'pc.png');
  const Flux = find(
    edges,
    ({ node }) => node.fluid.originalName === 'flux.png'
  );

  return (
    <Layout theme="blue" location={location}>
      <SEO
        title="Sobre a Central do Frete"
        description="Conheça quem é a Central do Frete, que faz  sua cotação de frete muito mais rápida e descomplicada."
      />
      <Jumbotron
        Image={Thumb}
        background={SECONDARY}
        title="Sobre nós"
        subtitle="Central do Frete: Facilitando conexões"
      />
      <Container className="pt-5 pb-5">
        <Row>
          <Col xs="12" md="6">
            <section>
              <p>
                Nós somos uma startup de tecnologia que está transformando a
                maneira como as empresas contratam fretes.
              </p>
              <p>
                Em um mundo onde descomplicar é a palavra de ordem, chegamos
                para criar uma experiência mais satisfatória tanto para quem
                compra quanto para quem vende.
              </p>
              <p>
                Contratar um frete pode ser mais fácil: nosso sistema seleciona
                as melhores transportadoras, você compara, contrata, faz o
                pagamento e consegue rastrear a carga.
              </p>
              <p>Tudo em um só lugar. Fácil assim.</p>
              <p>
                Acreditamos em fretes menos complicados, em vendedores menos
                preocupados e em clientes mais satisfeitos.
              </p>
              <About style={{ width: 130, height: 130, marginRight: 8 }} />
              <a
                href="https://blog.centraldofrete.com/o-surgimento-da-central-do-frete-com-uma-breve-historia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Conheça nossa história
              </a>
            </section>

            <section className="text-center pt-5">
              <Img fluid={Pc.node.fluid} alt="Computador" />
            </section>

            <section className="pt-5">
              <header>
                <h4>Economize tempo</h4>
              </header>
              <section>
                <p>
                  Abra mão dos inúmeros telefonemas e e-mails a fim de obter as
                  melhores cotações.
                </p>
                <p>
                  Na Central do Frete você contrata seu frete em poucos cliques.
                </p>
              </section>

              <footer className="pt-5">
                <Link
                  className="btn btn-primary btn-lg"
                  to="/cotacao-de-frete-online"
                >
                  COTAR FRETE
                </Link>
              </footer>
            </section>
          </Col>
          <Col md="6" className="d-none d-sm-block text-right">
            <Img fluid={Flux.node.fluid} alt="Fluxo" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

AboutPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default AboutPage;
